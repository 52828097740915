.grid-container {
  display: grid;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  justify-content: start;
}

.card-cameras {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
  background-color: #a4a4a4;
  aspect-ratio: 16 / 9;
}

.card-cameras img {
  object-fit: contain;
  background-color: #a4a4a4;
}

.overlay-cameras {
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.card-cameras:hover .overlay-cameras {
  transform: translateY(0);
  transition: all 0.3s ease;
}

.overlay-cameras button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
}

.foote-camerasr {
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.popup-content {
  position: relative;
  max-height: 70%;
  max-width: 70%;
}

.popup-content img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.close-icon {
  position: absolute;
  top: -60px;
  right: -60px;
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
  z-index: 1001;
}

.cameras-alert {
  flex-wrap: nowrap !important;
}

.cameras-alert div {
  gap: 1rem
}


@media screen and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .overlay-cameras {
    transform: translateY(0);
  }
  
  .card-cameras:hover .overlay-cameras {
    transform: none;
  }
}

@media screen and (max-width: 550px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .cameras-alert, .cameras-alert div {
    flex-wrap: wrap !important;
  }
  
  .cameras-alert button {
    margin-left: auto;
  }
}

@media screen and (max-width: 460px) {
  .mobil-layout {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .mobil-pb {
    padding-bottom: 1rem;
  }
}
