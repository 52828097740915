@mixin circle {
	content: '';
	position: absolute;
	border: 1px solid black;
	border-radius: 50%;
	width: 3px;
	height: 3px;
	top: calc(50% - 1.5px);
	user-select: none;
}

@font-face {
	font-family: 'RoadNumbers';
	src: url('../../../fonts/RoadNumbers.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.plate {
	display: flex;
	align-items: center;
	position: relative;
	font-size: 1.5rem;
	width: fit-content;
	white-space: nowrap;
	line-height: normal;
	font-family: 'RoadNumbers';

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 2px solid black;
		border-radius: 4px;
		user-select: none;
	}

	&-number {
		display: inline-block;
		position: relative;
		border: 2px solid black;
		border-radius: 4px;
		padding: 2px 2px 2px 8px;
		height: 30px;
		line-height: 22px;

		&::before {
			@include circle;
			left: 3px;
		}

		small {
			font-size: 80%;
		}
	}

	&-code {
		display: inline-block;
		position: relative;
		border: 2px solid black;
		border-left: 0;
		border-radius: 4px;
		padding: 2px 4px 2px 2px;
		font-size: 80%;
		height: 30px;

		&::after {
			@include circle;
			right: 3px;
		}

		.country {
			display: flex;
			align-items: center;
			font-size: 6px;
			font-weight: 700;
			width: fit-content;
			margin-left: auto;
			font-family: none;
		}
	}
}
