//
// Custom
//

@use 'sass:map';

@mixin transition {
	transition-duration: 0.2s;
	transition-timing-function: ease-out;
	transition-property: opacity;
}

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.preview {
	img {
		@include transition;
		opacity: 1;
	}

	&:hover {
		img {
			@include transition;
			opacity: 0.5;
			transition-property: opacity;
		}
	}
}

.transparent-button {
	background-color: transparent;
	border: none;
	color: inherit;
	margin: 0;
	padding: 0;
	font-size: inherit;
	font-weight: inherit;
}

.search-popup {
	transition: position .3s, top .3s, width .3s, box-shadow .3s, border-radius 0s, padding 0s;

	& table {
		display: none;
	}

	&:hover table,
	&:focus-within table,
	&.show table {
		display: table;
	}

	&:hover,
	&:focus-within,
	&.show {
		transition: position .3s, top .3s, width .3s, box-shadow .3s, border-radius 0s, padding 0s;
		position: absolute;
		top: 0;
		box-shadow: 0 1.6rem 3rem rgba(0,0,0,.175);
		padding: 1.5rem;
		border-radius: 1rem;
		width: 75vw;
		background-color: white;
		z-index: 1;
	}
}

// Sanches styles:
@media (min-width:1200px) {
	.col-21_32 {
		flex:0 0 auto;
		width:32%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:61%
	}
}
@media (min-width:1300px) {
	.col-21_32 {
		flex:0 0 auto;
		width:30%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:56%
	}
}
@media (min-width:1400px) {
	.col-21_32 {
		flex:0 0 auto;
		width:28%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:52%
	}
}
@media (min-width:1500px) {
	.col-21_32 {
		flex:0 0 auto;
		width:26%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:48%
	}
}
@media (min-width:1600px) {
	.col-21_32 {
		flex:0 0 auto;
		width:24%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:45%
	}
}
@media (min-width:1700px) {
	.col-21_32 {
		flex:0 0 auto;
		width:23%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:42%
	}
}
@media (min-width:1800px) {
	.col-21_32 {
		flex:0 0 auto;
		width:22%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:40%
	}
}
@media (min-width:1900px) {
	.col-21_32 {
		flex:0 0 auto;
		width:21%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:38%
	}
}
@media (min-width:2000px) {
	.col-21_32 {
		flex:0 0 auto;
		width:20%
	}
	.col-36_60 {
		flex:0 0 auto;
		width:36%
	}
}

#tasks {
	display: none;
}

#tasks .card-header {
	min-height: 3rem;
	padding-bottom: 1.3rem;
}

#tasks .card-title {
	margin: 0;
}

#tasks .card-body {
	padding: 0 0.5rem 0 1rem !important;
}

#tasks table  {
	vertical-align: middle;
	margin-bottom: 0.5rem !important;
}

#tasks table tr:last-child {
	border-bottom-style: hidden;
}

.avatar-sm {
	height: 40px;
	width: 40px;
}

.user_set {
	color: #fff;
	margin: -7px -2px 0 0;
}

.uname {
	margin-left: 5px;
}

.uname2 {
	margin-left: 7px;
}

.task_num {
	width: 50px;
}
.task_num a {
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
}

.task_uname {
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
	font-size: 13px;
}

.task_uphoto {
	display: inline-block;
}

.task_st_d {
	text-align: center;
}
.task_d {
	font-size: 0.8em;
}

.badge-outline-dark {
	background-color: #A9A9A9;
	font-weight: 500;
}
.badge-soft-dark {
	color: #35415a;
	background-color: #35415a1a;
	font-weight: 400;
}
.badge-soft-primary {
	background-color: #dbe1fe;
	color: #2e3f96;
	font-weight: 400;
}
.badge-soft-info {
	background-color: #e2dff6;
	color: #41387f;
	font-weight: 400;
}
.badge-soft-danger {
	color: #ed5555;
	background-color: #ed55551a;
	font-weight: 400;
}
.badge-soft-success {
	color: #28b765;
	background-color: #28b7651a;
	font-weight: 400;
}

.font-size-14 {
	font-size: 14px !important;
}

.task_title {
	padding: 0.7rem 0.5rem !important;
}
.task_title h5 {
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 700;
	font-size: 13px;
}

.typename, .placename {
	max-width: 250px;
	overflow-x: hidden;
}

