//
//  Content
//

.content {
	@include padding(map-get($content, padding-y) map-get($content, padding-x));

	display: flex;
	flex: 1 0 auto;

	.header + & {
		@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
			padding-top: map-deep-get($header, mobile, height);

			@media print {
				padding-top: 1rem;
			}
		}
	}
}
