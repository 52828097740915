//
//	Modal
//

.modal-content {
	@include dark-mode {
		background-color: $dark-modal-content-bg;
	}
}

.modal {
	&-backdrop {
		@include backdrop-filter-blur($modal-backdrop--blur);

		background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

		&.show {
			opacity: 1;
		}
	}
}

@media (min-width: 992px) {
	.modal-xxl {
		max-width: 800px;
	}
}

@media (min-width: 1200px) {
	.modal-xxl {
		max-width: 1140px;
	}
}

@media (min-width: 1900px) {
	.modal-xxl {
		max-width: 1805px;
	}
}
